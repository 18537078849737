import { useEffect, useMemo, useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import ListMenu from "./ListMenu";
import { IconListTypes } from "@/constant/iconList";
import { Menu } from "@/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavigationBar = () => {
  // const menuList = useSelector(
  //   (state: RootState) => state.home.dbData.navigationList
  // );

  const [openMenu, setOpenMenu] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleMenuHandler = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => setOpenMenu(false);

  const menuList: Menu[] = useMemo(() => {
    const menus: Menu[] = [];
    menus.push({
      menuTitle: "मुख्य पृष्ठ",
      navigationUrl: "Home",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.thumbtack,
      isActive: true,
      sequence: 0
    });
    menus.push({
      menuTitle: "बारे में",
      navigationUrl: "AboutUs",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.info,
      isActive: true,
      sequence: 0
    });
    menus.push({
      menuTitle: "परिवार",
      navigationUrl: "Family",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.peoplegroup,
      subMenu: [
        {
          menuTitle: "कर्णधार",
          navigationUrl: "Karndhar",
          tabIndex: "",
          menuClass: "",
          role: "",
          menuIcon: IconListTypes.usermd,
          isActive: false,
          sequence: 0
        },
        {
          menuTitle: "सदस्य",
          navigationUrl: "Member",
          tabIndex: "",
          menuClass: "",
          role: "",
          menuIcon: IconListTypes.usershield,
          isActive: false,
          sequence: 0
        },
      ],
      isActive: false,
      sequence: 0
    });
    menus.push({
      menuTitle: "अनुभव",
      navigationUrl: "Anubhav",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.handholdingmedical,
      isActive: true,
      sequence: 0
    });
    menus.push({
      menuTitle: "शिविर",
      navigationUrl: "Chikitsashivir",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.tent,
      subMenu: [
        {
          menuTitle: "चिकित्सा शिविर",
          navigationUrl: "Chikitsashivir",
          tabIndex: "",
          menuClass: "",
          role: "",
          menuIcon: IconListTypes.tent,
          isActive: false,
          sequence: 0
        },
      ],
      isActive: false,
      sequence: 0
    });
    menus.push({
      menuTitle: "लेख",
      navigationUrl: "Blogs",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.pensolid,
      isActive: false,
      sequence: 0
    });
    menus.push({
      menuTitle: "संपर्क",
      navigationUrl: "ContactUs",
      tabIndex: "",
      menuClass: "",
      role: "",
      menuIcon: IconListTypes.phone,
      isActive: true,
      sequence: 0
    });
    return menus;
  }, []);
  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    // <nav className="w-full">
    //   <If condition={device === "mobile"}>
    //     <a
    //       href="#"
    //       aria-haspopup="true"
    //       role="button"
    //       onClick={(e) => {
    //         e.preventDefault();
    //         openMobileMenuHandler();
    //       }}
    //       tabIndex={0}
    //       className="slicknav_btn slicknav_collapsed outline-none"
    //     >
    //       <span className="slicknav_menutxt">MENU</span>
    //       <span className="slicknav_icon">
    //         <span className="slicknav_icon-bar"></span>
    //         <span className="slicknav_icon-bar"></span>
    //         <span className="slicknav_icon-bar"></span>
    //       </span>
    //     </a>
    //   </If>
    //   <ListMenu
    //     menuData={menuList}
    //     device={device}
    //     openMenu={openMenu}
    //     openMenuHandle={openMobileMenuHandler}
    //   />
    // </nav>
    <nav className="w-full relative">
      {/* Desktop Navigation */}
      <div className="hidden md:flex justify-between items-center py-4 container space-x-16 ">
        <ListMenu menuData={menuList} device="desktop" closeMenu={closeMenu} />
        <button
          className="bg-primary text-white rounded-2xl px-8 py-3 font-heading"
          onClick={() => navigate("ContactUs")}
        >
          {t("home.donation")}
        </button>
      </div>

      {/* Mobile Navigation */}
      <div className="md:hidden flex justify-between items-center p-4">
        <button onClick={toggleMenuHandler} aria-label="Toggle Menu">
          {openMenu ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>
      {/* Mobile Menu */}
      {openMenu && (
        <div ref={menuRef} className="absolute top-full right-4 mt-2 bg-white shadow-lg rounded-lg p-4 z-50 max-w-[90vw] w-[calc(100vw-32px)] overflow-hidden">
          <ListMenu menuData={menuList} device="mobile" closeMenu={closeMenu} />
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;
