import { useTranslation } from "react-i18next";
import {
  groupBeans,
  leaves,
  rajivdixitjiimage,
  greenLeaves,
} from "@/assets";
import { PageTitle } from "@/components";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";

const SliderArea = () => {
  const quoteList = useSelector((state: RootState) => state.home.dbData.quotes);
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- slider Area Start--> */}
      <div className="container mx-auto px-4 space-y-8">
        {/* <!-- Single Slider --> */}
        <div className="w-full flex justify-between items-center space-x-8 px-16">
          <img src={groupBeans} alt="group of beans" className="w-20 h-20 md:w-32 md:h-32 object-contain" />
          <div>
            <PageTitle title={t("home.sspTitle")} lineWidth={18} />
            <div className="text-center font-medium text-xl lg:text-2xl mt-4">
              <span className="text-lightblack">{t("home.sspSubTitle")}</span>
            </div>
          </div>
          <img src={leaves} alt="leaves" className="w-20 h-20 md:w-28 md:h-28 object-contain rotate-12" />
        </div>
        <div className="flex flex-col items-center justify-center gap-12 px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center gap-8 w-full max-w-screen-xl">
            <div className="flex flex-col items-center">
              <div className="w-60 lg:w-80 flex flex-col items-center rounded-3xl border border-gray-200 shadow-lg">
                <img src={rajivdixitjiimage} alt="" className="w-[90%] mt-4" />
              </div>

              <p className="text-center mt-3 text-lg font-medium">
                राष्ट्रनायक,राष्ट्ररत्न <br />
                महान् <br />
                राजीव दीक्षित जी
                <br />
                (स्वदेशी के प्रखर वक्ता)
              </p>
            </div>
            {/* Quote Scroller */}
            <div className="w-full quotes-bg bg-contain lg:w-3/4 max-w-screen-lg h-[28rem] lg:h-[32rem] border rounded-3xl shadow-lg overflow-hidden">
              {/* <ul className="p-6 space-y-4 h-full overflow-y-auto animate-loop-scroll"> */}
              <ul className="p-6 space-y-4 h-full overflow-y-auto">
                {quoteList?.map((quote, index) => (
                  <li key={index} className="text-lg">
                    {quote.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="relative lg:left-[36rem]">
            <img src={greenLeaves} alt="" className="w-28 lg:w-40 mx-auto " />
          </div>
        </div>
      </div>
      {/* <!-- slider Area End--> */}
    </>
  );
};

export default SliderArea;
