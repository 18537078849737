import { IconType } from "react-icons";
import { LiaThumbtackSolid, LiaPenAltSolid } from "react-icons/lia";
import { BsInfoCircle } from "react-icons/bs";
import {
  PiBriefcaseLight,
  PiTentLight,
  PiPhoneLight,
  PiUsersFourLight,
  PiUserGearLight,
  PiUserPlusLight,
  PiHandHeartLight,
} from "react-icons/pi";

export enum IconListTypes {
  thumbtack = "thumbtack",
  pensolid = "pensolid",
  info = "info",
  peoplegroup = "peoplegroup",
  handholdingmedical = "handholdingmedical",
  usermd = "usermd",
  usershield = "usershield",
  briefcase = "briefcase",
  tent = "tent",
  phone = "phone",
}

const iconList: Record<IconListTypes, IconType> = {
  [IconListTypes.thumbtack]: LiaThumbtackSolid,
  [IconListTypes.pensolid]: LiaPenAltSolid,
  [IconListTypes.info]: BsInfoCircle,
  [IconListTypes.peoplegroup]: PiUsersFourLight,
  [IconListTypes.handholdingmedical]: PiHandHeartLight,
  [IconListTypes.usermd]: PiUserGearLight,
  [IconListTypes.usershield]: PiUserPlusLight,
  [IconListTypes.briefcase]: PiBriefcaseLight,
  [IconListTypes.tent]: PiTentLight,
  [IconListTypes.phone]: PiPhoneLight,
};

export default iconList;
