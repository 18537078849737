import { useSelector } from "react-redux";
import { DividerLine, PageTitle } from "@/components";
import { RootState } from "@/store/store";
import { motion } from 'framer-motion';

import { useTranslation } from "react-i18next";

const imageVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.5 } },
};

// const titleVariants = {
//   hidden: { opacity: 0, x: -30 },
//   visible: { opacity: 1, x: 0, transition: { delay: 0.4, duration: 0.5 } },
// };

// const descriptionVariants = {
//   hidden: { opacity: 0, x: 30 },
//   visible: { opacity: 1, x: 0, transition: { delay: 0.6, duration: 0.5 } },
// };

const Anubhav = () => {
  // const youtubeVideo = useSelector(
  //   (state: RootState) => state.externalApis.youtubeData
  // );
  const { t } = useTranslation();
  const feedbacks = useSelector((state: RootState) => state.home.dbData.feedbackList);
  // const videos: VideoDetail[] = youtubeVideo?.items?.map((item: any) => ({
  //   title: item.snippet.title,
  //   description: item.snippet.description,
  //   videoId: item.id.videoId,
  // }));
  return (
    <div className="container max-auto">
      {/* <Hero title={"से जुड़े लोगो का अनुभव"} /> */}
      <DividerLine />
      <div className="mx-auto px-4">
        <PageTitle title={t("anubhav.title")} lineWidth={25} />
      </div>
      <section className="py-16 px-8">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
          {feedbacks.map((item, index) => (
            <motion.div
              key={index}
              className="w-48 h-64 md:w-64 md:h-80 lg:w-72 lg:h-96 rounded-lg overflow-hidden shadow-lg bg-white flex flex-col mx-2"
              custom={index}
              initial="hidden"
              whileInView="visible"
              whileHover={{ scale: 1.05, rotate: 3 }}
              transition={{ type: "spring", stiffness: 200, damping: 10 }}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
              }}
              viewport={{ once: true, amount: 0.2 }}
            >
              <motion.img
                src={item.imageUrl}
                alt={item.title}
                className="w-full h-1/2 object-cover p-2 rounded-lg"
                variants={imageVariants}
              />

              <motion.div className="p-4 flex flex-col justify-between h-1/2">
                {/* Title with individual animation */}
                <h3 className="text-lg md:text-xl font-semibold text-center">
                  {item.title}
                </h3>

                {/* Description with its own animation */}
                <p className="text-sm md:text-base text-gray-600 text-center" >
                  {item.description}
                </p>
              </motion.div>
              <p className="flex justify-between h-3 bg-gradient-to-r from-secondary/70 to-primary/95"></p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Anubhav;
