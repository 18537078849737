// import { masale1, masale2, masale3, masale4 } from '@/assets';
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FeedbackResponse } from 'ssp-contracts';

// Sample card images
// const cards = [
//     { id: 1, image: masale1, title: "Herbal Care", description: "Nature's finest remedies for health." },
//     { id: 2, image: masale2, title: "Organic Foods", description: "Stay fit with fresh and organic food." },
//     { id: 3, image: masale3, title: "Wellness Retreat", description: "Relax and rejuvenate your mind and body." },
//     { id: 4, image: masale4, title: "Fitness Program", description: "Customized workouts for your goals." },
//     { id: 5, image: masale1, title: "Herbal Care", description: "Nature's finest remedies for health." },
// ];

interface SliderProps {
    cards: FeedbackResponse[];
}

const Slider = ({ cards }: SliderProps) => {
    const [activeIndex, setActiveIndex] = useState(0); // Start with the middle card
    const [numVisibleCards, setNumVisibleCards] = useState(1); // Show 5 cards on desktop

    const totalFeedbacks = cards?.length;
    useEffect(() => {
        const handleResize = () => {
            if (totalFeedbacks === 1) {
                setNumVisibleCards(1);
            } else if (totalFeedbacks === 2) {
                setNumVisibleCards(2);
            } else if (totalFeedbacks === 3 || totalFeedbacks === 4) {
                const visibleCards = window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 3 : 1; // 5 cards on desktop, 3 on mobile
                setNumVisibleCards(visibleCards);
            }
            else if (totalFeedbacks >= 5) {
                const visibleCards = window.innerWidth >= 1024 ? 5 : window.innerWidth >= 768 ? 3 : 1; // 5 cards on desktop, 3 on mobile
                setNumVisibleCards(visibleCards);
            }
        };

        handleResize(); // Set initial state based on current screen size
        window.addEventListener("resize", handleResize); // Listen for window resize

        // Clean up event listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const nextSlide = () => {
        setActiveIndex((prev) => (prev + 1) % totalFeedbacks);
    };

    const prevSlide = () => {
        setActiveIndex((prev) => (prev - 1 + totalFeedbacks) % totalFeedbacks);
    };

    const getVisibleCards = () => {
        const visibleCards = [];
        const totalCards = totalFeedbacks;

        // Calculate visible cards
        for (let i = 0; i < numVisibleCards; i++) {
            const index = (activeIndex + i - Math.floor(numVisibleCards / 2) + totalCards) % totalCards;
            visibleCards.push(cards[index]);
        }

        return visibleCards;
    };

    // const visibleCards = cards.slice(activeIndex, activeIndex + numVisibleCards);

    return (
        <div className="relative w-full py-12 flex justify-center items-center">
            {/* Left Arrow */}
            <button
                className="absolute left-4 p-3 bg-gray-200 rounded-full shadow-md hover:bg-gray-400 z-10"
                onClick={prevSlide}
            >
                <FaArrowLeft className="text-xl" />
            </button>

            {/* Cards Wrapper */}
            <div className="flex justify-center items-center w-full ">
                <AnimatePresence>
                    {getVisibleCards().map((card, index) => {
                        const isCenterCard = index === Math.floor(numVisibleCards / 2);
                        const isSideCard = index === Math.floor(numVisibleCards / 2) - 1 || index === Math.floor(numVisibleCards / 2) + 1;
                        const isFarSideCard = index === Math.floor(numVisibleCards / 2) - 2 || index === Math.floor(numVisibleCards / 2) + 2;

                        // Define scale and translation based on card position
                        const scale = isCenterCard ? 1.2 : isSideCard ? 0.9 : 0.7;
                        const translateX = isSideCard ? (index < Math.floor(numVisibleCards / 2) ? 50 : -50) : (isFarSideCard ? (index < Math.floor(numVisibleCards / 2) ? 180 : -180) : 0);
                        const zIndex = isCenterCard ? 10 : isSideCard ? 5 : 1;

                        return (
                            <motion.div
                                key={card.id}
                                className={`flex-shrink-0`}
                                style={{
                                    transform: `scale(${scale}) translateX(${translateX}px)`,
                                    zIndex: zIndex,
                                }}
                                initial={{ opacity: 0 }} // Start hidden
                                animate={{ opacity: 1 }} // Fade in
                                exit={{ opacity: 0 }} // Fade out
                                transition={{
                                    duration: 2, // Base duration for all transitions
                                    delay: isCenterCard ? 0 : isSideCard ? 0.2 : 0.4, // Delay each card's transition
                                    ease: "easeInOut", // Smooth easing
                                }} // Smooth transition
                            // whileHover={{ scale: 1.1 }}
                            >
                                <div className="w-48 h-64 md:w-64 md:h-80 lg:w-72 lg:h-96 rounded-lg overflow-hidden shadow-lg bg-white flex flex-col mx-2">
                                    <img
                                        src={card.imageUrl}
                                        alt={card.title}
                                        className="w-full h-1/2 object-cover p-2 rounded-lg"
                                    />
                                    <div className="p-4 flex flex-col justify-between h-1/2">
                                        <h2 className="text-lg md:text-xl font-semibold text-center">
                                            {card.title}
                                        </h2>
                                        <p className="text-sm md:text-base text-gray-600 text-center">
                                            {`${card.description}`}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </div>

            {/* Right Arrow */}
            <button
                className="absolute right-4 p-3 bg-gray-200 rounded-full shadow-md hover:bg-gray-400 z-10"
                onClick={nextSlide}
            >
                <FaArrowRight className="text-xl" />
            </button>
        </div>
    );
};

export default Slider;
